<template>
  <div class="container">

    <video muted loop playsinline autoplay>
      <source src="/assets/video/cherry-blossoms.webm" type="video/webm">
      <source src="/assets/video/cherry-blossoms.mp4" type="video/mp4">
    </video>

    <div class="grid">
      <div class="col">
        <inline-svg :src="$getImageSrc('kichin')" class="logo"></inline-svg>
      </div>
      <div class="col">
        <h1>Design-led web solutions.</h1>
      </div>
      <div class="col inner">
        <strong>Services.</strong>
        <ul>
          <li>Consulting</li>
          <li>Design</li>
          <li>UI/UX</li>
          <li>Development</li>
        </ul>
        <div class="divider"></div>
        <strong>Select work.</strong>
        <ul>
          <li><a href="https://arborgreen.nz">arborgreen.nz</a></li>
          <li><a href="https://gough.co.nz">gough.co.nz</a></li>
          <li><a href="https://rhcnz.com">rhcnz.com</a></li>
          <li><a href="https://hughesdevelopments.co.nz">hughesdevelopments.co.nz</a></li>
          <li><a href="https://tuatarastructures.com">tuatarastructures.com</a></li>
          <li><a href="https://rubix.nz">rubix.nz</a></li>
          <li><a href="https://visionforgrowth.co.nz">visionforgrowth.co.nz</a></li>
          <li><a href="https://ohinetahi.co.nz">ohinetahi.co.nz</a></li>
          <li><a href="https://research.lincoln.ac.nz">research.lincoln.ac.nz</a></li>
          <li><a href="https://toddproperty.co.nz">toddproperty.co.nz</a></li>
          <li><a href="https://mcf.co.nz">mcf.co.nz</a></li>
        </ul>
      </div>
      <div class="col contact">
        <a  href="mailto:hello@kichin.co"><inline-svg :src="$getImageSrc('email-white')"></inline-svg></a>
      </div>
    </div>
  </div>
</template>


<script>

  export default {
    data() {
      return {
        
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>
